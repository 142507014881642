import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';

const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                name: 'login',
                component: () => import('./pages/LoginPage.vue')
            },
            {
                path: '/overview-old',
                name: 'overview-old',
                component: () => import('./pages/OverviewPage.old.vue'),
            },
            {
                path: '/overview',
                name: 'overview',
                component: () => import('./pages/OverviewPage.vue'),
                children: [
                    {
                        path: 'manufacture',
                        component: () => import('./pages/overview/ManufacturePage.vue'),
                    },
                    {
                        path: 'scrap',
                        component: () => import('./pages/overview/ScrapPage.vue'),
                    },
                    {
                        path: 'toppings-manu',
                        component: () => import('./pages/overview/ToppingsManuPage.vue'),
                    },
                    {
                        path: 'toppings-scrap',
                        component: () => import('./pages/overview/ToppingsScrapPage.vue'),
                    },
                ]
            },
            {
                path: '/purchase',
                name: 'purchase',
                component: () => import('./pages/PurchasePage.vue')
            },
            {
                path: '/inventory',
                name: 'inventory',
                component: () => import('./pages/InventoryPage.vue'),
                children: [],
            },
            // {
            //     path: '/table',
            //     name: 'table',
            //     component: () => import('./components/TableDemo.vue')
            // },
        ]
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, _from, next) => {
  let shopHash = localStorage.getItem('shop_id')

  if (to.path === '/') {
    next()
  } else {
    if (shopHash == null) {
      next({ path: '/' })
    } else {
      next()
    }
  }
})

export default router;
